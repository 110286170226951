@import "base";
@mixin base_kr($size, $line, $font-weight) {
  font-family: $font_family_kr !important;
  font-size: ptr($size) !important;
  font-style: normal !important;
  font-weight: $font-weight !important;
  letter-spacing: ptr($font_letter_spacing_base) !important;
  line-height: $line !important;
}

@mixin base_bebas($size, $line, $font-weight) {
  font-family: $font_family_en-bebas !important;
  font-size: ptr($size) !important;
  font-style: normal !important;
  font-weight: $font-weight !important;
  line-height: $line !important;
}

@mixin base_unica($size, $line, $font-weight) {
  font-family: $font_family_en-unica !important;
  font-size: ptr($size) !important;
  font-style: normal !important;
  font-weight: $font-weight !important;
  letter-spacing: ptr($font_letter_spacing_base) !important;
  line-height: $line !important;
}

/*
 font - size + weight + lang
 2 depth 로 유지 (에디터에서 바로가기 동작 때문)
 */

.font_72 {
  &_bold_kr {
    @include base_kr(72, 1.25, $font_weight_bold);
  }
}

.font_60 {
  &_bold_kr {
    @include base_kr(60, 1.25, $font_weight_bold);
  }
}

.font_48 {
  &_bold_kr {
    @include base_kr(48, 1.25, $font_weight_bold);
  }
}

.font_40 {
  &_medium_kr {
    @include base_kr(40, 1.4, $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(40, 1.4, $font_weight_bold);
  }
}

.font_36 {
  &_medium_kr {
    @include base_kr(36, calc(50 / 36), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(36, calc(50 / 36), $font_weight_bold);
  }
}

.font_32 {
  &_medium_kr {
    @include base_kr(32, calc(45 / 32), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(32, calc(45 / 32), $font_weight_bold);
  }
}

.font_28 {
  &_regular_kr {
    @include base_kr(28, calc(39 / 28), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(28, calc(39 / 28), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(28, calc(39 / 28), $font_weight_bold);
  }
}

.font_24 {
  &_regular_kr {
    @include base_kr(24, calc(34 / 24), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(24, calc(34 / 24), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(24, calc(34 / 24), $font_weight_bold);
  }
}

.font_20 {
  &_regular_kr {
    @include base_kr(20, calc(28 / 20), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(20, calc(28 / 20), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(20, calc(28 / 20), $font_weight_bold);
  }
}

.font_22 {
  &_regular_kr {
    @include base_kr(22, calc(28 / 22), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(22, calc(28 / 22), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(22, calc(28 / 22), $font_weight_bold);
  }
}

.font_16 {
  &_regular_kr {
    @include base_kr(16, 1.5, $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(16, 1.5, $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(16, 1.5, $font_weight_bold);
  }
}

.font_14 {
  &_regular_kr {
    @include base_kr(14, calc(22 / 14), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(14, calc(22 / 14), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(14, calc(22 / 14), $font_weight_bold);
  }
}

.font_13 {
  &_regular_kr {
    @include base_kr(13, calc(20 / 13), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(13, calc(20 / 13), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(13, calc(20 / 13), $font_weight_bold);
  }
}

.font_12 {
  &_regular_kr {
    @include base_kr(12, calc(17 / 12), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(12, calc(17 / 12), $font_weight_normal);
  }

  &_bold_kr {
    @include base_kr(12, calc(17 / 12), $font_weight_bold);
  }
}

.font_10 {
  &_regular_kr {
    @include base_kr(10, calc(14 / 10), $font_weight_regular);
  }

  &_medium_kr {
    @include base_kr(10, calc(14 / 10), $font_weight_normal);
  }
}

/*
 EN - bebas font
 */

.font_bebas_96 {
  &_bold {
    @include base_bebas(96, 0.9375, $font-weight-bold);
  }
}

.font_bebas_90 {
  &_bold {
    @include base_bebas(90, calc(90 / 96), $font-weight-bold);
  }
}

.font_bebas_60 {
  &_bold {
    @include base_bebas(60, 0.9, $font-weight-bold);
  }
}

.font_bebas_48 {
  &_bold {
    @include base_bebas(48, 0.9, $font-weight-bold);
  }
}

.font_bebas_40 {
  &_bold {
    @include base_bebas(40, 0.9, $font-weight-bold);
  }
}

.font_bebas_32 {
  &_bold {
    @include base_bebas(32, 1, $font-weight-bold);
  }
}

.font_bebas_28 {
  &_bold {
    @include base_bebas(28, 1, $font-weight-bold);
  }
}

.font_bebas_24 {
  &_bold {
    @include base_bebas(24, 1, $font-weight-bold);
  }
}

.font_bebas_20 {
  &_bold {
    @include base_bebas(20, 1.4, $font-weight-bold);
  }
}

.font_bebas_16 {
  &_bold {
    @include base_bebas(16, 1.5, $font-weight-bold);
  }
}

.font_bebas_15 {
  &_bold {
    @include base_bebas(15, 1, $font-weight-bold);
  }
}

.font_bebas_14 {
  &_bold {
    @include base_bebas(14, 1.5, $font-weight-bold);
  }
}

.font_bebas_12 {
  &_bold {
    @include base_bebas(12, 1, $font-weight-bold);
  }
}

/*
 En - Unica font
 */
.font_unica_32 {
  &_bold {
    @include base_unica(32, calc(45 / 32), $font-weight-bold);
  }
}

.font_unica_24 {
  &_regular {
    @include base_unica(24, calc(34 / 24), $font-weight-regular);
  }

  &_medium {
    @include base_unica(24, calc(34 / 24), $font-weight-normal);
  }

  &_bold {
    @include base_unica(24, calc(34 / 24), $font-weight-bold);
  }
}

.font_unica_20 {
  &_regular {
    @include base_unica(20, calc(28 / 20), $font-weight-regular);
  }

  &_medium {
    @include base_unica(20, calc(28 / 20), $font-weight-normal);
  }

  &_bold {
    @include base_unica(20, calc(28 / 20), $font-weight-bold);
  }
}

.font_unica_16 {
  &_regular {
    @include base_unica(16, calc(22 / 16), $font-weight-regular);
  }

  &_medium {
    @include base_unica(16, calc(22 / 16), $font-weight-normal);
  }

  &_bold {
    @include base_unica(16, calc(22 / 16), $font-weight-bold);
  }
}

.font_unica_14 {
  &_regular {
    @include base_unica(14, calc(20 / 14), $font-weight-regular);
  }

  &_medium {
    @include base_unica(14, calc(20 / 14), $font-weight-normal);
  }

  &_bold {
    @include base_unica(14, calc(20 / 14), $font-weight-bold);
  }
}

.font_unica_12 {
  &_regular {
    @include base_unica(12, calc(17 / 12), $font-weight-regular);
  }

  &_medium {
    @include base_unica(12, calc(17 / 12), $font-weight-normal);
  }

  &_bold {
    @include base_unica(12, calc(17 / 12), $font-weight-bold);
  }
}

.font_unica_10 {
  &_regular {
    @include base_unica(10, 1.4, $font-weight-regular);
  }
}

html[is_mobile="false"] {
  .font_72 {
    &_bold_kr_pc {
      @include base_kr(72, 1.25, $font_weight_bold);
    }
  }

  .font_60 {
    &_bold_kr_pc {
      @include base_kr(60, 1.25, $font_weight_bold);
    }
  }

  .font_48 {
    &_bold_kr_pc {
      @include base_kr(48, 1.25, $font_weight_bold);
    }
  }

  .font_40 {
    &_medium_kr_pc {
      @include base_kr(40, 1.4, $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(40, 1.4, $font_weight_bold);
    }
  }

  .font_36 {
    &_medium_kr_pc {
      @include base_kr(36, calc(50 / 36), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(36, calc(50 / 36), $font_weight_bold);
    }
  }

  .font_32 {
    &_medium_kr_pc {
      @include base_kr(32, calc(45 / 32), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(32, calc(45 / 32), $font_weight_bold);
    }
  }

  .font_28 {
    &_regular_kr_pc {
      @include base_kr(28, calc(39 / 28), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(28, calc(39 / 28), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(28, calc(39 / 28), $font_weight_bold);
    }
  }

  .font_24 {
    &_regular_kr_pc {
      @include base_kr(24, calc(34 / 24), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(24, calc(34 / 24), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(24, calc(34 / 24), $font_weight_bold);
    }
  }

  .font_20 {
    &_regular_kr_pc {
      @include base_kr(20, calc(28 / 20), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(20, calc(28 / 20), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(20, calc(28 / 20), $font_weight_bold);
    }
  }

  .font_22 {
    &_regular_kr_pc {
      @include base_kr(22, calc(28 / 22), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(22, calc(28 / 22), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(22, calc(28 / 22), $font_weight_bold);
    }
  }

  .font_16 {
    &_regular_kr_pc {
      @include base_kr(16, 1.5, $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(16, 1.5, $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(16, 1.5, $font_weight_bold);
    }
  }

  .font_14 {
    &_regular_kr_pc {
      @include base_kr(14, calc(22 / 14), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(14, calc(22 / 14), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(14, calc(22 / 14), $font_weight_bold);
    }
  }

  .font_12 {
    &_regular_kr_pc {
      @include base_kr(12, calc(17 / 12), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(12, calc(17 / 12), $font_weight_normal);
    }

    &_bold_kr_pc {
      @include base_kr(12, calc(17 / 12), $font_weight_bold);
    }
  }

  .font_10 {
    &_regular_kr_pc {
      @include base_kr(10, calc(14 / 10), $font_weight_regular);
    }

    &_medium_kr_pc {
      @include base_kr(10, calc(14 / 10), $font_weight_normal);
    }
  }

  /*
   EN - bebas font
   */

  .font_bebas_96 {
    &_bold_pc {
      @include base_bebas(96, 0.9375, $font-weight-bold);
    }
  }

  .font_bebas_90 {
    &_bold_pc {
      @include base_bebas(90, calc(90 / 96), $font-weight-bold);
    }
  }

  .font_bebas_60 {
    &_bold_pc {
      @include base_bebas(60, 0.9, $font-weight-bold);
    }
  }

  .font_bebas_48 {
    &_bold_pc {
      @include base_bebas(48, 0.9, $font-weight-bold);
    }
  }

  .font_bebas_40 {
    &_bold_pc {
      @include base_bebas(40, 0.9, $font-weight-bold);
    }
  }

  .font_bebas_32 {
    &_bold_pc {
      @include base_bebas(32, 1, $font-weight-bold);
    }
  }

  .font_bebas_24 {
    &_bold_pc {
      @include base_bebas(24, 1, $font-weight-bold);
    }
  }

  .font_bebas_20 {
    &_bold_pc {
      @include base_bebas(20, 1.4, $font-weight-bold);
    }
  }

  .font_bebas_16 {
    &_bold_pc {
      @include base_bebas(16, 1.5, $font-weight-bold);
    }
  }

  .font_bebas_14 {
    &_bold_pc {
      @include base_bebas(14, 1.5, $font-weight-bold);
    }
  }

  /*
   En - Unica font
   */
  .font_unica_32 {
    &_bold_pc {
      @include base_unica(32, calc(45 / 32), $font-weight-bold);
    }
  }

  .font_unica_24 {
    &_regular_pc {
      @include base_unica(24, calc(34 / 24), $font-weight-regular);
    }

    &_medium_pc {
      @include base_unica(24, calc(34 / 24), $font-weight-normal);
    }

    &_bold_pc {
      @include base_unica(24, calc(34 / 24), $font-weight-bold);
    }
  }

  .font_unica_20 {
    &_regular_pc {
      @include base_unica(20, calc(28 / 20), $font-weight-regular);
    }

    &_medium_pc {
      @include base_unica(20, calc(28 / 20), $font-weight-normal);
    }

    &_bold_pc {
      @include base_unica(20, calc(28 / 20), $font-weight-bold);
    }
  }

  .font_unica_16 {
    &_regular_pc {
      @include base_unica(16, calc(22 / 16), $font-weight-regular);
    }

    &_medium_pc {
      @include base_unica(16, calc(22 / 16), $font-weight-normal);
    }

    &_bold_pc {
      @include base_unica(16, calc(22 / 16), $font-weight-bold);
    }
  }

  .font_unica_14 {
    &_regular_pc {
      @include base_unica(14, calc(20 / 14), $font-weight-regular);
    }

    &_medium_pc {
      @include base_unica(14, calc(20 / 14), $font-weight-normal);
    }

    &_bold_pc {
      @include base_unica(14, calc(20 / 14), $font-weight-bold);
    }
  }

  .font_unica_12 {
    &_regular_pc {
      @include base_unica(12, calc(17 / 12), $font-weight-regular);
    }

    &_medium_pc {
      @include base_unica(12, calc(17 / 12), $font-weight-normal);
    }

    &_bold_pc {
      @include base_unica(12, calc(17 / 12), $font-weight-bold);
    }
  }

  .font_unica_10 {
    &_regular_pc {
      @include base_unica(10, 1.4, $font-weight-regular);
    }
  }
}

html[is_mobile="true"] {
  .font_72 {
    &_bold_kr_mo {
      @include base_kr(72, 1.25, $font_weight_bold);
    }
  }

  .font_60 {
    &_bold_kr_mo {
      @include base_kr(60, 1.25, $font_weight_bold);
    }
  }

  .font_48 {
    &_bold_kr_mo {
      @include base_kr(48, 1.25, $font_weight_bold);
    }
  }

  .font_40 {
    &_medium_kr_mo {
      @include base_kr(40, 1.4, $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(40, 1.4, $font_weight_bold);
    }
  }

  .font_36 {
    &_medium_kr_mo {
      @include base_kr(36, calc(50 / 36), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(36, calc(50 / 36), $font_weight_bold);
    }
  }

  .font_32 {
    &_medium_kr_mo {
      @include base_kr(32, calc(45 / 32), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(32, calc(45 / 32), $font_weight_bold);
    }
  }

  .font_28 {
    &_regular_kr_mo {
      @include base_kr(28, calc(39 / 28), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(28, calc(39 / 28), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(28, calc(39 / 28), $font_weight_bold);
    }
  }

  .font_24 {
    &_regular_kr_mo {
      @include base_kr(24, calc(34 / 24), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(24, calc(34 / 24), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(24, calc(34 / 24), $font_weight_bold);
    }
  }

  .font_20 {
    &_regular_kr_mo {
      @include base_kr(20, calc(28 / 20), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(20, calc(28 / 20), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(20, calc(28 / 20), $font_weight_bold);
    }
  }

  .font_22 {
    &_regular_kr_mo {
      @include base_kr(22, calc(28 / 22), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(22, calc(28 / 22), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(22, calc(28 / 22), $font_weight_bold);
    }
  }

  .font_16 {
    &_regular_kr_mo {
      @include base_kr(16, 1.5, $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(16, 1.5, $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(16, 1.5, $font_weight_bold);
    }
  }

  .font_14 {
    &_regular_kr_mo {
      @include base_kr(14, calc(22 / 14), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(14, calc(22 / 14), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(14, calc(22 / 14), $font_weight_bold);
    }
  }

  .font_12 {
    &_regular_kr_mo {
      @include base_kr(12, calc(17 / 12), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(12, calc(17 / 12), $font_weight_normal);
    }

    &_bold_kr_mo {
      @include base_kr(12, calc(17 / 12), $font_weight_bold);
    }
  }

  .font_10 {
    &_regular_kr_mo {
      @include base_kr(10, calc(14 / 10), $font_weight_regular);
    }

    &_medium_kr_mo {
      @include base_kr(10, calc(14 / 10), $font_weight_normal);
    }
  }

  /*
   EN - bebas font
   */

  .font_bebas_96 {
    &_bold_mo {
      @include base_bebas(96, 0.9375, $font-weight-bold);
    }
  }

  .font_bebas_90 {
    &_bold_mo {
      @include base_bebas(90, calc(90 / 96), $font-weight-bold);
    }
  }

  .font_bebas_60 {
    &_bold_mo {
      @include base_bebas(60, 0.9, $font-weight-bold);
    }
  }

  .font_bebas_48 {
    &_bold_mo {
      @include base_bebas(48, 0.9, $font-weight-bold);
    }
  }

  .font_bebas_40 {
    &_bold_mo {
      @include base_bebas(40, 0.9, $font-weight-bold);
    }
  }

  .font_bebas_32 {
    &_bold_mo {
      @include base_bebas(32, 1, $font-weight-bold);
    }
  }

  .font_bebas_24 {
    &_bold_mo {
      @include base_bebas(24, 1, $font-weight-bold);
    }
  }

  .font_bebas_20 {
    &_bold_mo {
      @include base_bebas(20, 1.4, $font-weight-bold);
    }
  }

  .font_bebas_16 {
    &_bold_mo {
      @include base_bebas(16, 1.5, $font-weight-bold);
    }
  }

  .font_bebas_14 {
    &_bold_mo {
      @include base_bebas(14, 1.5, $font-weight-bold);
    }
  }

  /*
   En - Unica font
   */
  .font_unica_32 {
    &_bold_mo {
      @include base_unica(32, calc(45 / 32), $font-weight-bold);
    }
  }

  .font_unica_24 {
    &_regular_mo {
      @include base_unica(24, calc(34 / 24), $font-weight-regular);
    }

    &_medium_mo {
      @include base_unica(24, calc(34 / 24), $font-weight-normal);
    }

    &_bold_mo {
      @include base_unica(24, calc(34 / 24), $font-weight-bold);
    }
  }

  .font_unica_20 {
    &_regular_mo {
      @include base_unica(20, calc(28 / 20), $font-weight-regular);
    }

    &_medium_mo {
      @include base_unica(20, calc(28 / 20), $font-weight-normal);
    }

    &_bold_mo {
      @include base_unica(20, calc(28 / 20), $font-weight-bold);
    }
  }

  .font_unica_16 {
    &_regular_mo {
      @include base_unica(16, calc(22 / 16), $font-weight-regular);
    }

    &_medium_mo {
      @include base_unica(16, calc(22 / 16), $font-weight-normal);
    }

    &_bold_mo {
      @include base_unica(16, calc(22 / 16), $font-weight-bold);
    }
  }

  .font_unica_14 {
    &_regular_mo {
      @include base_unica(14, calc(20 / 14), $font-weight-regular);
    }

    &_medium_mo {
      @include base_unica(14, calc(20 / 14), $font-weight-normal);
    }

    &_bold_mo {
      @include base_unica(14, calc(20 / 14), $font-weight-bold);
    }
  }

  .font_unica_12 {
    &_regular_mo {
      @include base_unica(12, calc(17 / 12), $font-weight-regular);
    }

    &_medium_mo {
      @include base_unica(12, calc(17 / 12), $font-weight-normal);
    }

    &_bold_mo {
      @include base_unica(12, calc(17 / 12), $font-weight-bold);
    }
  }

  .font_unica_10 {
    &_regular_mo {
      @include base_unica(10, 1.4, $font-weight-regular);
    }
  }
}
