$black: #141414;
$blue: #1c75ff;
$blue-100: #1c75ff;
$blue-10: #ebf3fe;
$blue-100: #1c75ff;
$white: #fff;
$bright-5: rgb(255 255 255 / 5%);
$bright-10: rgb(255 255 255 / 10%);
$bright-20: rgb(255 255 255 / 20%);
$bright-60: rgb(255 255 255 / 60%);
$bright-70: rgb(255 255 255 / 70%);
$bright-95: rgb(255 255 255 / 95%);
$brown: #704c00;
$burgundy: #900;
$cream: #fff9e2;
$dim-5: rgb(20 20 20 / 5%);
$dim-10: rgb(20 20 20 / 10%);
$dim-30: rgb(20 20 20 / 30%);
$dim-60: rgb(20 20 20 / 60%);
$gray-1: #fafafa;
$gray-5: #f5f5f5;
$gray-10: #e7e7e7;
$gray-20: #d9d9d9;
$gray-50: #a2a2a2;
$gray-80: #6b6b6b;
$gray-100: #f8f8f8;
$green: #009444;
$green-10: #ebfef3;
$green-100: #009444;
$light-blue: #ebf3fe;
$light-green: #ebfef3;
$light-purple: #eeebff;
$light-red: #fceeec;
$pink: #ffe4ff;
$purple-10: #eeebff;
$purple-100: #7a44a0;
$red-80: #d92a0f;
$red-10: #fceeec;
$pink-10: #fdf0fe;
$pink-20: #ffe4ff;
$sky: #99e0ff;
$orange-100: #ff6a00;
$brown-10: #fff9e2;
$brown-100: #704c00;
$product-bg: #f6f6f6;
